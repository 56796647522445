import React from "react"
import {useEffect} from "react"
import { navigate } from "gatsby"
import { handleAuthentication } from "../services/auth"

const Callback = () => {
  handleAuthentication()
  
  useEffect(() => {
    navigate('/app/');
  }, []);

  return (
    <p>
      Login successful! <br></br>Loading app...
    </p>
  )
}

export default Callback
